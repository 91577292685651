import React from "react";
import emailicon from "../images/email.svg";
import linkedinicon from "../images/linkedin.svg";
import Blocktitle from "../components/Blocktitle";
import HandshakeLottie from "./HandshakeLottie";
import { Link } from "gatsby";

export default () => (
    <footer className="site--footer container">
    <div className="availability">
      <HandshakeLottie/>
      <Link to="/contact">
        <Blocktitle blockTitle="I’m immediately available for full time career opportunities, please contact me here!" />
      </Link>
    </div>
    <nav className="social--menu">
      <ul>
        <li className="icon email">
          <a href="/contact">
            <img src={emailicon} alt="E-mail icon" />
          </a>
        </li>
        <li className="icon linkedin">
          <a href="https://www.linkedin.com/in/fanny-benisek/" target="_blank" rel="noopener noreferrer">
            <img src={linkedinicon} alt="linkein icon" />
          </a>
        </li>
      </ul>
    </nav>
  </footer>
)