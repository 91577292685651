import React, {Component} from "react";
import { Link } from "gatsby";

const ListLink = props => (
    <li>
      <Link to={props.to}>{props.children}</Link>
    </li>
)

class MainMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {addClass: false}
  }
  toggle() {
    this.setState({addClass: !this.state.addClass});
  }
  render() {
    if(this.state.addClass && (typeof document !== `undefined`)) {
      document.body.classList.add("toggled");
    } else if (typeof document !== `undefined`) {
      document.body.classList.remove("toggled");
    }
    return (
        <div className="menu--wrapper">
          <div className="menutoggle">
            <button className="menutoggle-box" onClick={this.toggle.bind(this)}>
              <span className="bar bar-1"></span>
              <span className="bar bar-2"></span>
              <span className="bar bar-3"></span>
            </button>
          </div>
          <nav className="main--menu">
            <ul>
              <ListLink to="/">Home</ListLink>
              <ListLink to="/about">About</ListLink>
              <ListLink to="/projects">Projects</ListLink>
              <ListLink to="/contact">Contact</ListLink>
            </ul>
          </nav>
        </div>
    );
  }
}
export default MainMenu