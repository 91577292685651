import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from '../lotties/handshake.json';

class HandshakeLottie extends Component {
  state = {isStopped: true}

  render(){
    const defaultOptions = {
      renderer: "canvas",
      loop: false,
      autoplay: false,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <button className="handshake lottie--icon"
            onMouseEnter={() => this.setState({isStopped: false})}
            onMouseLeave={() => this.setState({isStopped: true})}>

        <Lottie options={defaultOptions}
            height={150}
            width={150}
            isStopped={this.state.isStopped}
        />
      </button>
    )
  }
}

export default HandshakeLottie